// Angular Files
import { DOCUMENT, Location } from '@angular/common';
import { Component, DoCheck, Inject, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

// Angular Material Files
import { MatIconRegistry } from '@angular/material/icon';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

// Other External Files
import { Subscription } from 'rxjs';

// Payment Integration Files
import {
    BasePaymentProcessorComponent,
    BillingInfoComponent,
    BillingInfoFields,
    PaymentIntegrationFieldValue
} from 'apps/public-portal/src/app/payment-integrations/base/components';
import { FisService } from 'apps/public-portal/src/app/payment-integrations/fis/service';
import {
    ECheckAccountOwnerTypeEnum,
    ECheckAccountTypeEnum,
    PaymentMethodTypeEnum
} from 'apps/public-portal/src/app/payment-integrations/base/models';
import { PaymentProcessorProvider } from 'apps/public-portal/src/app/payment-integrations/base';

// Teller Online Files
import { AuthService, CartModel, CartService, InboundRedirectService } from 'apps/public-portal/src/app/core/services';

// Teller Online Library Files
import {
    TellerOnlineAppService,
    TellerOnlineErrorHandlerService,
    TellerOnlineSiteMetadataService
} from 'teller-online-libraries/core';
import { TellerOnlineMessageService, TellerOnlineValidationService } from 'teller-online-libraries/shared';
import { StateOrProvince } from 'apps/public-portal/src/app/shared/constants';

// Represents the component.js script loaded from FIS.
declare const component: {

}

// Represents an HTML element containing the logic that will allow collection of payment info.
interface Tokenizer extends HTMLElement {
    jwt: any;
    token: string;
    canDelete: boolean;
    paymentType: string;
    styles: string;
    openIframe: any;
    addEventListener: any;
}

@Component({
    selector: 'app-fis',
    templateUrl: './fis.component.html',
    styleUrls: ['./fis.component.scss'],
    host: {
        class: 'fis'
    }
})
export class FisComponent extends BasePaymentProcessorComponent implements OnInit, DoCheck, OnDestroy {
    @ViewChild(BillingInfoComponent) billingInfoComponent!: BillingInfoComponent;
    @ViewChild("fisIframe") fisIframe;

    // Public variables
    public fisFieldError: string = null;
    public fisFieldsAvailable: boolean = false;
    public fisIframeLoaded: boolean = false;

    public get creditDisclaimer() {
        return `By clicking the ${this.forEdit ? 'Save' : 'Pay'} button, I authorize County of Santa Clara to charge the credit card indicated on this web form for the noted amount. I understand that in the event this transaction is rejected by my financial institution, I may be subject to an $85 returned payment fee. I understand a late payment is liable to a 10% penalty. I understand that County of Santa Clara requires at least 2 weeks prior notice to cancel this authorization.

        <b>Note:</b> Your credit card information is not provided to County of Santa Clara. Therefore, your Credit Card, Debit Card or E-Check information does not appear on, and is not retained on, the County of Santa Clara network, computers or data storage systems.`;
    }

    public requiredAddressFields: BillingInfoFields[] = [
        BillingInfoFields.addressLine1,
        BillingInfoFields.addressState,
        BillingInfoFields.addressCity,
        BillingInfoFields.phone
    ]
    public disabledAddressFields: BillingInfoFields[] = [
        BillingInfoFields.addressLine1,
        BillingInfoFields.addressState,
        BillingInfoFields.addressCountry,
        BillingInfoFields.addressCity,
        BillingInfoFields.phone,
        BillingInfoFields.addressZip
    ]

    public TokenizerAction = TokenizerAction; // To be used in the markup

    // Private variables
    private _formChangeSubscription: Subscription;
    private _feeToken: string;
    private _preparePaymentError: string;
    private _creditCardConfirmed: boolean;

    // Constants
    private COMPONENT_SCRIPT_ID = 'component';

    // #region BasePaymentProcessorComponent property overrides
    public set paymentMethodData(paymentMethodData) {
        if (this._formChangeSubscription) this._formChangeSubscription.unsubscribe();

        super.paymentMethodData = paymentMethodData;

        // dynamically add all of the cc fields to the controls for the form group
        if (paymentMethodData.type == PaymentMethodTypeEnum.CreditCard) {
            Object.keys(this.CC_FIELDS).filter(f => f != 'ccname').forEach(name => {
                this.paymentDetailsForm.addControl(name, new FormControl(new PaymentIntegrationFieldValue(null, '', name, this.CC_FIELDS[name])));
            });
            this.paymentDetailsForm.addControl(this.CC_FIELDS.ccname, new FormControl(paymentMethodData.billingInfo.fullName));
            // dynamically add all of the echeck fields to the controls for the form group
        } else if (paymentMethodData.type == PaymentMethodTypeEnum.ECheck) {
            Object.keys(this.ECHECK_FIELDS).filter(f => !['checkname', 'checktype', 'checkownertype'].includes(f)).forEach(name => {
                this.paymentDetailsForm.addControl(name, new FormControl(new PaymentIntegrationFieldValue(null, '', name, this.ECHECK_FIELDS[name])));
            });
            this.paymentDetailsForm.addControl(this.ECHECK_FIELDS.checkname, new FormControl(paymentMethodData.billingInfo.fullName));
            this.paymentDetailsForm.addControl(this.ECHECK_FIELDS.checktype, new FormControl({ value: [], disabled: true }));
            this.paymentDetailsForm.addControl(this.ECHECK_FIELDS.checkowner, new FormControl({ value: [], disabled: true }));
        }

        if (paymentMethodData) {
            this._formChangeSubscription = this.paymentDetailsForm.valueChanges.subscribe((value) => {
                if (paymentMethodData.type == PaymentMethodTypeEnum.CreditCard) {
                    paymentMethodData.billingInfo.fullName = value[this.CC_FIELDS.ccname];
                } else {
                    paymentMethodData.echeckAccountType = value[this.ECHECK_FIELDS.checktype];
                    paymentMethodData.billingInfo.fullName = value[this.ECHECK_FIELDS.checkname];
                }
            });
        }
    }

    public get paymentMethodData() {
        return super.paymentMethodData;
    }

    // #endregion

    // Subscriptions

    constructor(
        private fisService: FisService,
        private errorHandlerService: TellerOnlineErrorHandlerService,
        private dialog: MatDialog,
        @Inject(DOCUMENT) private document: Document,
        ngZone: NgZone,
        location: Location,
        appService: TellerOnlineAppService,
        siteMetadataService: TellerOnlineSiteMetadataService,
        inboundRedirectService: InboundRedirectService,
        cartService: CartService,
        authService: AuthService,
        messageService: TellerOnlineMessageService,
        validationService: TellerOnlineValidationService,
        paymentProvider: PaymentProcessorProvider,
        matIconRegistry: MatIconRegistry,
        domSanitizer: DomSanitizer
    ) {
        super(appService, ngZone, location, siteMetadataService, inboundRedirectService, cartService, authService, messageService, validationService, paymentProvider, matIconRegistry, domSanitizer);
        this.loading = true;
        this._creditCardConfirmed = false;
    }

    //#region OnInit Implementation

    ngOnInit() {
        super.ngOnInit();

        // Request the token ahead of time (asynchonously),
        // but we don't need to save it because it will get stored on the cookie so subsequent requests will use that
        // and won't request a new one
        this._requestJwt();

        this.paymentProvider.configLoaded$.subscribe(loaded => {
            if (loaded) {
                this._initializeComponentJS();
            }
        });
    }

    //#endregion

    //#region DoCheck Implementation

    ngDoCheck(): void {
        super.ngDoCheck();
    }

    //#endregion

    //#region OnDestroy Implementation

    ngOnDestroy(): void {
        super.ngOnDestroy();
        if (this._formChangeSubscription) this._formChangeSubscription.unsubscribe();
        this.paymentMethodData.cardNumber = undefined;
        this.paymentMethodData.cardExpiry = undefined;
        this.paymentMethodData.billingInfo.addressCity = undefined;
        this.paymentMethodData.billingInfo.addressLine1 = undefined;
        this.paymentMethodData.billingInfo.addressLine2 = undefined;
        this.paymentMethodData.billingInfo.addressRegion = undefined;
        this.paymentMethodData.billingInfo.addressState = undefined;
        this.paymentMethodData.billingInfo.addressZip = undefined;
        this.paymentMethodData.billingInfo.phone = undefined;
    }

    //#endregion

    //#region Event Handlers

    onSubmit_validateAndSubmit = (e) => {
        e.preventDefault();
        this._validateAndSubmit();
    };

    //#endregion

    //#region BasePaymentProcessorComponent Implementation

    public override async savePaymentMethod() {
        this.appService.triggerPageLoading('Saving information...');

        try {
            let response = await this.fisService.savePaymentMethod({
                paymentMethodData: this.paymentMethodData,
                paymentToken: this._paymentToken,
                paymentMethodId: this.paymentMethodId
            });

            this.paymentMethodId = response.paymentMethodId;

            this.updateUrl();

            this.processingComplete.emit(response.last4);
        } catch (e) {
            this.processingError.emit(e);
        } finally {
            this.finishedDataEntry(true);
            this.appService.finishPageLoading();
        }
    }

    public override async payCart() {
        this.appService.triggerPageLoading("Processing payment, do not refresh your browser...");

        // Wait for the response.
        await this.cartService.updateCart({
            guestEmailAddress: this.paymentMethodData.billingInfo.email,
            rememberPaymentMethod: this.paymentMethodData.rememberPaymentMethod,
            paymentMethodId: null //unset any previously saved paymentMethodId incase a previous attempt to use a saved method was made
        });

        let proceed = await this.cartService.refreshCart(this._cartGuid, this.paymentMethodData.type);
        if (proceed) {
            // we need to re-prepare the payment incase there was an error while trying to pay previously
            await this._preparePaymentAndAssessFees(false);

            if (!this._preparePaymentError) {
                try {
                    let postPaymentResponse = await this.fisService.payCart({
                        cartId: this._cartId,
                        paymentMethodData: this.paymentMethodData,
                        paymentToken: this._paymentToken,
                        inboundRedirectSourceId: this.inboundRedirectService.redirectSourceId,
                        validationToken: this._feeToken,
                        processorPaymentMethodType: this.paymentMethodData.processorPaymentMethodType
                    });

                    if (postPaymentResponse.cartStatus) {
                        this.processingComplete.emit(postPaymentResponse);
                    } else {
                        // Display the appropriate message for the current payment method type
                        let notChargedMessage;
                        switch (this.paymentMethodData.type) {
                            case PaymentMethodTypeEnum.ECheck:
                                notChargedMessage = "Your account has not been charged.";
                                break;
                            case PaymentMethodTypeEnum.CreditCard:
                            default:
                                notChargedMessage = "Your card has not been charged.";
                                break;
                        }
                        this.messageService.notification("Unable to process payment. " + notChargedMessage + " Reason: " +
                            postPaymentResponse.errorMessage, "error", 5000);
                    }

                } catch (e) {
                    this.processingError.emit(e);
                } finally {
                    this.finishedDataEntry(true);
                    this.appService.finishPageLoading();
                }
            } else {
                this.finishedDataEntry(true);
                this.appService.finishPageLoading();
            }
        } else {
            this.finishedDataEntry(true);
            this.appService.finishPageLoading();
        }
    }

    public override async handleCartChanges(cart: CartModel, cartItemCount: number) {
        if (cart.items.length > 0 && cart.items?.length != cartItemCount) {
            if (this.location.path() == "/checkout") {
                if (!this._paymentToken) {
                    return;
                }

                await this._preparePaymentAndAssessFees();
                this.appService.finishPageLoading();
            }
        }
    }

    //#endregion

    //#region helpers

    public async onClick_openIFrame(e, action: TokenizerAction) {
        this.appService.triggerPageLoading();

        e.preventDefault = true;
        await this._openIFrame(action);
    }

    public onEnter_preventDefault(e) {
        // Just prevent form submission, the onClick event will trigger the IFrame
        e.preventDefault = true;
    }

    public tokenForEdit(action: TokenizerAction) {
        if (action === TokenizerAction.EditExisting) {
            return this.paymentMethodData.walletToken ?? this._paymentToken;
        }

        return '';
    }

    onCheck_agreeCreditCardDisclaimer(checked: boolean) {
        this._creditCardConfirmed = checked;
    }

    shouldDisableCreditCardSubmit() {
        return !this._creditCardConfirmed && this.paymentMethodData.type == PaymentMethodTypeEnum.CreditCard
    }

    private async _requestJwt(throwError = false): Promise<string> {
        let response = await this.fisService.requestJwt(this.paymentMethodData.type, throwError);

        return response?.token;
    }

    private async _openIFrame(action: TokenizerAction) {
        let fisPopup: MatDialogRef<any>;

        this.fisIframeLoaded = false;
        try {
            fisPopup = this.dialog.open(this.fisIframe, {
                // ensure users can't click outside of the window to close it
                disableClose: true,
                // Modals by default have max width 80vw so this is not an issue for mobile
                width: '400px',
                // Modals by default have a max height of 10%, so this is not an issue for shorter browsers,
                // 515px is the min size before FIS starts scrolling
                height: '515px'
            });
            let token = await this._requestJwt(true);

            let tokenizer = this.document.querySelector('fis-tokenization') as Tokenizer | null;

            if (!tokenizer) {
                throw "Fis tokenizer element has not loaded in time. This is likely temporary unless it's happening frequently.";
            }

            tokenizer.token = this.tokenForEdit(action);
            tokenizer.jwt = token;
            tokenizer.canDelete = false;
            tokenizer.paymentType = this.paymentMethodData.type == PaymentMethodTypeEnum.ECheck ? 'bank' : 'card';
            tokenizer.styles = ``;

            tokenizer.removeAllListeners();
            // handle events
            tokenizer.addEventListener('rendered', (e) => {
                this.ngZone.run(async () => {
                    // when the iframe is rendered, set the flag to true so we can hide the loading icon
                    this.fisIframeLoaded = true;
                });
            });
            tokenizer.addEventListener('tokenAdded', (e) => {
                this.ngZone.run(async () => {
                    // Close the popup because we have a successful token and the iframe is unloaded
                    fisPopup.close();
                    await this._updateFisManagedFields(e.detail);
                });
            });

            tokenizer.addEventListener('tokenEdited', (e) => {
                this.ngZone.run(async () => {
                    // Close the popup because we have a successful token and the iframe is unloaded
                    fisPopup.close();
                    await this._updateFisManagedFields(e.detail);
                });
            });

            tokenizer.addEventListener('jwtRequested', () => {
                this.ngZone.run(async () => {
                    tokenizer.jwt = await this._requestJwt();
                });
            });

            tokenizer.addEventListener('errors', (e) => {
                this.ngZone.run(() => {
                    this._logError("errors", e);
                });
            });

            tokenizer.openIframe();
        } catch (e) {
            if (fisPopup) {
                // Close the popup because there was an error and we couldn't load the iframe
                fisPopup.close();
            }
            this.messageService.notification("Unable to initiate payment entry. Please refresh the page and try again.", "error", 5000);
            e.message = "FIS iframe failed to be created due to error: '" + e.message + "'";
            this.errorHandlerService.handleError(e);
        } finally {
            setTimeout(() => {
                this.appService.finishPageLoading();
            }, 1000);
        }
    }

    private _logError(errorType: string, error: any) {
        if (errorType === "Declined") {
            this.messageService.notification("Payment entry was declined.", "error", 5000);
        } else if (errorType === "Cancelled") {
            this.messageService.notification("Payment entry was cancelled.", "error", 5000);
        } else {
            this.messageService.notification("Unable to complete payment entry. Please refresh the page and try again.", "error", 5000);
            this.errorHandlerService.handleError({ message: "FIS iframe failed to be created. Response from FIS was '" + errorType + "' with additional info: " + error });
        }

        this.appService.finishPageLoading();
    }

    private async _validateAndSubmit() {
        this.appService.triggerPageLoading('Validating information...');

        if (this._preparePaymentError) {
            this.fisFieldError = this._preparePaymentError;
        } else if (!this._paymentToken && this.paymentMethodData.type == this.PaymentMethodTypeEnum.CreditCard) {
            this.fisFieldError = "Payment details are required";
        } else {
            this.fisFieldError = null;
        }

        let additionalErrors: { [key: string]: string } = {};

        if (this.fisFieldError)
            additionalErrors.fisError = this.fisFieldError;

        // Only attempt to pay the cart if the rest of the form validation has passed
        if (this.validationService.runValidation(this.paymentDetailsForm, null, false, additionalErrors)) {
            if (this.forEdit) {
                this.savePaymentMethod();
            } else {
                this.payCart();
            }
        } else {
            this.appService.finishPageLoading();
        }
    }

    private _initializeComponentJS() {
        // check if the script has already been created
        if (this.fisService.tokenizationUrl) {
            if (!this.document.querySelector("#" + this.COMPONENT_SCRIPT_ID)) {
                const node = this.document.createElement('script');
                node.id = this.COMPONENT_SCRIPT_ID;
                node.src = this.fisService.tokenizationUrl;
                node.type = 'text/javascript';
                node.async = false;

                this.document.getElementsByTagName('head')[0].appendChild(node);
            }

            this._waitForComponentJS();
        } else {
            this.messageService.notification("Unable to initialize payment entry form. Payment cannot be completed. Please refresh the page and try again.", "error", 5000);
            this.errorHandlerService.handleError({ message: "FIS iframe could not be initialized because tokenizationUrl was not provided." });
        }
    }

    /** Every 100ms check if Component.js is defined, if it is, configure it, otherwise, repeat */
    private _waitForComponentJS() {
        setTimeout(() => {
            if (typeof component != 'undefined' && this.paymentMethodData.type) {
                this.loading = false;
                this.fisFieldsAvailable = true;
            } else {
                this._waitForComponentJS();
            }
        }, 100);
    }

    private async _preparePaymentAndAssessFees(displayCalculating: boolean = true) {
        if (displayCalculating) this.appService.triggerPageLoading("Calculating Convenience Fee...");
        this._preparePaymentError = null;

        try {
            this.fisFieldsAvailable = false;

            // If a payment is being made, assess the fees now so they can be displayed to the user before the payment is finalized.
            let response = await this.fisService.preparePaymentAndAssessFees({
                cartId: this._cartId,
                paymentMethodData: this.paymentMethodData,
                paymentToken: this._paymentToken,
                inboundRedirectSourceId: this.inboundRedirectService.redirectSourceId
            });
            if (response.paymentToken) {
                this.paymentMethodData.processorPaymentMethodType = response.processorPaymentMethodType;

                // Save the fee token so it can be used to submit the payment later.
                this._feeToken = response.paymentToken;
                this.paymentProvider.defaultConfig.overrideConvenienceFee(response.convenienceFee);
            }
        } catch (e) {
            this._preparePaymentError = e?.errorMessage && e.errorMessage != "An internal error occurred."
                ? e.errorMessage
                : "An error occurred while calculating convenience fees. Please re-enter your payment information and try again.";

            // if the cart is undefined then it has timed out do not throw this error
            // the user is going to be redirected out of this flow and a message has already been show
            if (this.cartService.cartGuid) {
                throw e;
            }
        } finally {
            this.fisFieldsAvailable = true;
        }
    }

    private async _updateFisManagedFields(response) {
        this._paymentToken = response.token;
        this.fisFieldError = null;

        if (this.cartService.cartExists()) {
            await this.cartService.updateCart({
                paymentMethodId: null
            });
        }

        if (this.paymentMethodData.type == this.PaymentMethodTypeEnum.CreditCard) {
            this.paymentMethodData.cardNumber = response.accountSuffix;
            this.paymentMethodData.cardExpiry = response.expirationDateMonth.toString().padStart(2, '0') + '/' + response.expirationDateYear.toString().substring(2);
            // Sometimes they don't give us a brand back but cardType is required, to get around that issue we can specify "NA" and set it back to null later
            this.paymentMethodData.cardType = response.brand ?? "NA";
        } else if (this.paymentMethodData.type == this.PaymentMethodTypeEnum.ECheck) {
            this.paymentMethodData.echeckRoutingNumber = response.routingSuffix;
            this.paymentMethodData.echeckAccountNumber = response.accountSuffix;

            this.paymentMethodData.echeckAccountType = ECheckAccountTypeEnum[response.bankAccountType.charAt(0) + response.bankAccountType.slice(1).toLowerCase()];
            this.paymentDetailsForm.get(this.ECHECK_FIELDS.checktype).setValue(this.paymentMethodData.echeckAccountType);

            this.paymentMethodData.echeckAccountOwnerType = ECheckAccountOwnerTypeEnum[response.accountHolderType.charAt(0) + response.accountHolderType.slice(1).toLowerCase()];
            this.paymentDetailsForm.get(this.ECHECK_FIELDS.checkowner).setValue(this.paymentMethodData.echeckAccountOwnerType);
        }

        if (!response.billingAddress || !response.billingCity || !response.billingPostalCode || !response.billingState || !response.phoneNumber) {
            this.errorHandlerService.logErrorToServer("FIS response did not contain all billing information. Response: " + JSON.stringify(response));
        }

        this.paymentDetailsForm.get(this.billingInfoComponent.BILLING_INFO_FIELDS.addressLine1).setValue(response.billingAddress);
        this.paymentDetailsForm.get(this.billingInfoComponent.BILLING_INFO_FIELDS.addressCity).setValue(response.billingCity);
        this.paymentDetailsForm.get(this.billingInfoComponent.BILLING_INFO_FIELDS.addressState).setValue(StateOrProvince.GetByCode(response.billingState));
        this.paymentDetailsForm.get(this.billingInfoComponent.BILLING_INFO_FIELDS.addressZip).setValue(response.billingPostalCode);
        this.paymentDetailsForm.get(this.billingInfoComponent.BILLING_INFO_FIELDS.phone).setValue(response.phoneNumber);

        if (!this.forEdit) {
            await this._preparePaymentAndAssessFees();
        }

        this.appService.finishPageLoading();
    }

    //#endregion
}

/** Represents how the iframe is being opened.
 * If a token for the payment method already exists, EditExisting can be passed to edit that method.
 * AddNew must be used when adding a payment initially, or to change the card number.
 */
enum TokenizerAction {
    AddNew,
    EditExisting
}
